.newsContainer {
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    height: 50vh;


}

@media (max-width:992px) {
    .newsContainer {
        margin-top: 24px;
    }
}


.newsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.newsTitle {
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 1.3px;
    height: 42px;
    text-align: justify;
}

.newsDetailsContainer {
    /* width: 400px; */
    /* height: 45vh; */
    /* background-color: #c4c4c4; */
    margin-bottom: 8px;
    padding: 8px;
    text-align: center;
}

.newsTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* margin: 8px 0px 8px 0px; */
}

.newsImageContainer {
    border-radius: 12px;
    width: 100px;
    height: 100px;
    aspect-ratio: 1/1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.hl {
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: 16%;
    margin-top: 24px;
}

.dateTypeNews {
    font-size: 14px;
    line-height: 1.3px;
    color: #ffffff;
    opacity: 0.6;
    margin-bottom: 4px;
}

@media (max-width:1200px) {
    .dateTypeNews {
        font-size: 13px;
    }
}