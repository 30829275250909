.canceledTableContainer {
    height: 40vh;
    overflow-y: scroll
}

.tableHead {
    border-bottom: none;
    color: gray;

}

.canceledTableContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.trBody {
    background-color: #000;
    color: white;
}

td:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
}

td:nth-child(4) {
    border-radius: 0px 8px 8px 0px;
}