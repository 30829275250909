.typesBadge p {
    margin: 8px 0px 0px 0px;
    letter-spacing: 0.05rem;
    background-color: #000;
    color: #fff;
    border-radius: 0px;
    padding: 8px 16px 8px 16px;
}

.typesBadge {
    cursor: pointer;

}