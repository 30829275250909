.carouselItem {
    /* display: block; */
    background-repeat: no-repeat;
    background-size: 100% 50vh;
    background-position: center;
    background-color: #000000;
    height: 50vh;
    /* margin-top: 51px; */
    width: 100%;
    /* padding: 0px 24px 0px 24px; */
}

/* 
.CaouselContainer {
    width: 60%;

} */

.carousel-inner {
    border-radius: 16px;
}