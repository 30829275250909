.inputContainer {
    width: 106%;
}

.date {
    background-color: none;
    height: 58px;
    width: 99%;
    border: 1px solid #fff;
    padding-left: 8px;
    cursor: pointer;
    border-radius: 3px;
}

.PhoneInput {
    width: 104%;
    height: 40px;
    border: 1px solid #fff;
    padding: 8px;
}

.phoneNumber {
    height: 58px;
    background-color: white;
    border-radius: 3px;
}

.border-radius-2 .modal-content {
    border-radius: 5px;
    background-color: #212529;
}

.modal-header {
    border-bottom-width: 0px;
    width: 100.1%;
    background-color: #000;
    color: white;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhoneInputInput {
    border: none;
    color: #000000;
}

.modalLogo {
    width: 200px;
    height: 50px;
    background-image: url('../../assets/images/cashabet365.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* margin-bottom: 24px; */
}

.submit {
    width: 100%;
    background-color: #000;
    border: none;
    color: #fff;
    /* font-weight: 500; */
}

.submit:hover {
    transition: background-color 0.3s ease-in;
    background-color: #ff9f1a;
}