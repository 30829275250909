.tableHead {
    border-bottom: none;
    color: gray;

}

.upcomingTableContainer {
    height: 40vh;
    overflow-y: auto;
}

.upcomingTableContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.td1 {
    border-radius: 8px 0px 0px 8px;
    font-size: 14px;
    /* font-weight: 700; */
}

.td4 {
    border-radius: 0px 8px 8px 0px;
}