.tableHead {
     border-bottom: none;
     color: gray;

}

.gameTableContainer {
     max-height: 40vh;
     overflow-y: auto;
     padding-right: 0
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
     /* for Chrome, Safari, and Opera */
}

.table {
     border-collapse: separate;
     border-spacing: 0 8px;
}

.trBody {
     background-color: #000;
     color: white;
}

.td3 {
     border-radius: 0px 8px 8px 0px;
}