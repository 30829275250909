@font-face {
    font-family: PTsans;
    src: url(./assets/fonts/PTSans-Regular.ttf);
}



* {
    margin: 0;
    padding: 0;
    font-family: 'PTsans';
}


body {
    overflow-x: hidden;
    background-color: #212529;
}

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}