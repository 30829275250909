.FooterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 43vh;

}

h4 {
    color: white;
    letter-spacing: 1.5px;
    margin-bottom: 32px;
}

.footerLogo {
    width: 200px;
    height: 50px;
    background-image: url('../../assets/images/cashabet365.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 24px;
}

.linksContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.socialMediaContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 16px;
    margin-top: 32px;
    cursor: pointer;

}


.socialMediaIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    padding: 10px;
    width: 50px;
    height: 50px;
    /* background-color: red; */
    border: 2px solid white;
    border-radius: 100px;
    transition: background-color 0.3s ease-in
}

.socialMediaIcons:hover {
    background-color: #ff9f1a;
}

.copyRight {
    margin-top: 64px;
    color: white;
    opacity: 0.5;
}